<template>
    <van-popup v-model="visible" round position="bottom" closeable>
        <van-tabs v-model="active" color="#1989fa">
            <van-tab title="短信登录">
                <van-form validate-first @submit="handleSubmit">
                    <van-field
                        v-model="form.phone"
                        name="手机号"
                        label="手机号"
                        placeholder="请输入手机号"
                        :rules="[{ required: true, message: '请输入手机号' }]"
                    />
                    <van-field
                        v-model="form.code"
                        center
                        label="短信验证码"
                        placeholder="请输入短信验证码"
                        :rules="[{ required: true, message: '请输入短信验证码' }]"
                    >
                        <template #button>
                            <bind-phone-button :phone=form.phone scene="login" />
                        </template>
                    </van-field>
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit" :loading="submitting">登录</van-button>
                    </div>
                </van-form>
            </van-tab>
            <van-tab title="密码登录">
                <van-form validate-first @submit="handleSubmit">
                    <van-field
                        v-model="form.phone"
                        name="手机号"
                        label="手机号"
                        placeholder="请输入手机号"
                        :rules="[{ required: true, message: '请输入手机号' }]"
                    />
                    <van-field
                        v-model="form.login_password"
                        type="password"
                        label="密码"
                        placeholder="请输入登录密码"
                        :rules="[{ required: true, message: '请输入登录密码' }]"
                    >
                    </van-field>
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit" :loading="submitting">登录</van-button>
                    </div>
                </van-form>
            </van-tab>
        </van-tabs>
    </van-popup>
</template>

<script>
import BindPhoneButton from '@/components/BindPhoneButton'
import { login } from '@/api/login'
import { LOGIN_USER } from '@/utils/constants'

export default {
    name: 'UserLogin',
    components: { BindPhoneButton },
    data () {
        return {
            active: 0,
            visible: false,
            submitting: false,
            form: {
                phone: '',
                code: '',
                login_password: '',
                type: 'phone'
            }
        }
    },
    methods: {
        handleSubmit () {
            this.submitting = true
            this.form.type = this.active === 0 ? 'phone' : 'password'
            login(this.form).then(res => {
                this.$ls.set(LOGIN_USER, res.data);
                this.$notify({ type: 'success', message: '登录成功' })
                this.$eventBus.$emit('login-success', { ...res.data })
                this.visible = false
            }).finally(() => {
                this.submitting = false
            })
        },
        show () {
            this.visible = true
        }
    }
}
</script>

<style lang="less">
    .bind-phone-header {
        flex-shrink: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 48px;
        text-align: center;
    }
</style>
