<template>
    <div class="hand-box">
        <div class="checker-brand-box">
            <van-image fit="contain" height="100" :radius="4" :src="church.logo" />
            <div class="brand-title">{{ church.name }}</div>
        </div>

        <div class="checker-buttons" style="bottom: 176px" v-if="loading">
            <van-loading type="spinner" />
        </div>

        <div v-else>
            <div class="checker-buttons" v-if="showLoginButton">
                <van-button type="info" class="button" @click="$refs.userLogin.show()" :loading="logging">手机号登录</van-button>
            </div>
            <div class="checker-buttons" v-if="showBindPhone">
                <van-button type="primary" class="button" @click="$refs.bindPhone.show()" :loading="bindPhoneLoading">绑定手机号</van-button>
            </div>
        </div>

        <div class="checker-footer">
            {{ church.meta.slogan }}
        </div>

        <set-login-password ref="setLoginPassword" @ok="handleSetLoginPasswordSuccess" @skip="handleSkipPassword" />
        <bind-phone ref="bindPhone" @ok="handleBindPhoneSuccess" />
        <user-login ref="userLogin" />
    </div>
</template>

<script>
import { LOGIN_USER } from '@/utils/constants'
import { getUserInfo } from '@/api/user'
import { getChurchByDomain } from '@/api/media'
import SetLoginPassword from './SetLoginPassword.vue'
import BindPhone from './BindPhone.vue'
import UserLogin from './UserLogin.vue'

export default {
    name: 'HandBox',
    components: { BindPhone, UserLogin, SetLoginPassword },
    props: {
        accessProvider: {
            type: Promise,
            required: true
        }
    },
    data () {
        return {
            church: {
                name: '',
                logo: '',
                meta: {
                    slogan: ''
                }
            },
            loading: true,
            user: null,
            accessConfiguration: {
                code: ''
            },
            skipSetPassword: false,
            showBingPassword: false,
            bindPhoneLoading: false,
            showBindPhone: false,
            showLoginButton: false,
            logging: false
        }
    },
    methods: {
        handleSetLoginPasswordSuccess () {
            this.loading = true
            getUserInfo().then(res => {
                this.user = res.data
                this.$ls.set(LOGIN_USER, res.data)
                this.checkPerm()
            }).finally(() => {
                this.loading = false
            })
        },
        handleSkipPassword () {
            this.skipSetPassword = true
            this.checkPerm()
        },
        handleBindPhoneSuccess () {
            this.bindPhoneLoading = true
            getUserInfo().then(res => {
                this.user = res.data
                this.$ls.set(LOGIN_USER, res.data)
                this.checkPerm()
            }).finally(() => {
                this.bindPhoneLoading = false
            })
        },
        checkPerm () {
            //弹出绑定手机号
            this.showBindPhone = (this.user && !this.user.phone)
            if (this.showBindPhone) {
                this.$refs.bindPhone.show()
                return
            }
            //弹出设置密码
            this.showBingPassword = (this.user && !this.user.login_password)
            if (this.showBingPassword && !this.skipSetPassword) {
                this.$refs.setLoginPassword.show()
                return
            }
            //展示登录逻辑
            if (this.accessConfiguration.login_required === 1) {
                this.showLoginButton = this.user == null
                if (this.showLoginButton) {
                    return
                }
            }

            this.$emit('checkSuccess')
        }
    },
    async created() {
        //slogan  标语
        //religious_license 宗教信息服务许可
        //filling_no  备案号
        //public_security_filling_no  公安备案号
        //获取基础信息
        const churchRes = await getChurchByDomain()
        this.church = churchRes.data

        //获取配置和重新啦用户信息
        this.user = this.$ls.get(LOGIN_USER)
        if (this.user) {
            const userRes = await getUserInfo()
            this.user = userRes.data
            this.$ls.set(LOGIN_USER, this.user)
        }

        //获取网站配置信息
        this.accessProvider.then((res) => {
            this.accessConfiguration = res.data
            this.checkPerm()
        }).catch(() => {
            this.$notify({ type: 'warning', message: '网络错误,请稍后再试' })
        }).finally(() => {
            this.loading = false
        })

        //获取保存的访问令牌
        this.$eventBus.$on('login-success', () => {
            this.logging = true
            getUserInfo().then(res => {
                this.user = res.data
                this.$ls.set(LOGIN_USER, res.data)
                this.checkPerm()
            }).finally(() => {
                this.logging = false
            })
        })
    }
}
</script>

<style lang="less" scoped>
.hand-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
}
.checker-brand-box {
    position: absolute;
    top: 114px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
    text-align: center;
    font-weight: lighter;

    .brand-title {
        margin: 8px 0px;
        color: #323233;
    }
}
.down-title {
    font-size: 26px;
    text-align: center;
    font-weight: lighter;
    margin: 8px 0px;
    color: #323233;
}
.checker-buttons {
    position: absolute;
    bottom: 76px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .button {
        width: 260px;
        text-align: center;
        box-sizing: border-box;
        outline: none;
        position: relative;
        margin-bottom: 16px;
        height: 50px;
        font-size: 16px;
    }
}
.checker-footer {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    color: #7e838c;
}
</style>
