<template>
    <hand-box v-if="!canAccess" @checkSuccess="handleCanAccess" :accessProvider="accessProv" :passTag="passTag" />
</template>

<script>
import { getPresetConfig } from '@/api/common'
import HandBox from '@/components/HandBox'

export default {
    name: 'Index',

    components: { HandBox },
    computed: {
        accessProv () {
            return getPresetConfig({ key: 'ACCESS_CONFIGURATION', scope: 'common' })
        },
        passTag () {
            return 'INDEX'
        }
    },
    methods: {
        handleCanAccess () {
            this.$router.push({ path: '/video' })
        }
    },
    data () {
        return {
            canAccess: false
        }
    }
};
</script>
