import {axios} from '@/utils/request'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
    return axios({
        url: '/auth/login',
        method: 'post',
        data: parameter
    })
}

export function getInfo() {
    return axios({
        url: '/auth/info',
        method: 'get'
    })
}

export function logout() {
    return axios({
        url: '/auth/logout',
        method: 'post'
    })
}

export function sendSms(params) {
    return axios({
        url: 'commons/sms',
        method: 'post',
        data: params,
    })
}

export function getAuthRedirectUrl(params) {
    return axios({
        url: '/auth/redirect_url',
        method: 'get',
        params: params
    })
}


