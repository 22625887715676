<template>
    <van-popup v-model="visible" round position="bottom" :close-on-click-overlay="false">
        <div class="bind-phone-header">设置登录密码</div>
        <van-form validate-first @submit="handleSubmit">
            <van-field
                v-model="form.password"
                type="password"
                name="登录密码"
                label="登录密码"
                placeholder="请输入登录密码"
                :rules="[{ required: true, message: '请输入登录密码' }]"
            />
            <van-field
                v-model="form.password_confirm"
                type="password"
                label="确认登录密码"
                placeholder="请输入登录密码"
                :rules="[{ required: true, message: '请输入登录密码' }]"
                >
            </van-field>
            <div style="margin: 16px;">
                <van-row gutter="16">
                    <van-col span="12">
                        <van-button round block type="info" @click="handleSkipSetPassword">跳过</van-button>
                    </van-col>
                    <van-col span="12">
                        <van-button round block type="info" native-type="submit" :loading="submitting">确定</van-button>
                    </van-col>
                </van-row>
            </div>
        </van-form>
    </van-popup>
</template>

<script>
import { setLoginPassword } from '@/api/user'

export default {
    name: 'SetLoginPassword',
    data () {
        return {
            visible: false,
            submitting: false,
            form: {
                password: '',
                password_confirm: ''
            }
        }
    },
    methods: {
        handleSubmit () {
            this.submitting = true
            setLoginPassword(this.form).then(res => {
                this.$notify({ type: 'success', message: res.messsage })
                this.$emit('ok', res.data)
                this.visible = false
            }).finally(() => {
                this.submitting = false
            })
        },
        handleSkipSetPassword () {
            this.$emit('skip')

            this.visible = false
        },
        show () {
            this.visible = true
        }
    }
}
</script>

<style lang="less">
    .bind-phone-header {
        flex-shrink: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 48px;
        text-align: center;
    }
</style>
