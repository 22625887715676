<template>
    <van-button
        plain 
        hairline 
        type="info"
        native-type="button"
        @click="handleSendSms"
        size="small"
        :loading="sending"
        loading-text="发送中..."
        :disabled="send"
    >{{ buttonText }}</van-button>
</template>

<script>
import { sendBindPhoneSms } from '@/api/user'
import { sendSms } from '@/api/login'

export default {
    name: 'BindPhoneButton',
    props: {
        phone: {
            type: String,
            require: true
        },
        scene: {
            type: String,
            require: true
        }
    },
    data () {
        return {
            sending: false,
            send: false,
            waitingTimer: null,
            waitingTime: 60
        }
    },
    computed: {
        buttonText () {
            if (this.send) {
                return this.waitingTime + '秒后再发送'
            }
            return '发送验证码'
        }
    },
    watch : {
        waitingTime (val) {
            if (val <= 0) {
                this.send = false
                this.waitingTime = 60
                clearInterval(this.waitingTimer)
            }
        }
    },
    methods: {
        handleSendSms () {
            const reg = /^1[3456789]\d{9}$/

            const valid = reg.test(this.phone)
            if (!valid) {
                this.$notify({ type: 'warning', message: '手机号不合法' })
                return
            }
            if (this.$ls.get('sms_lock')) {
                this.$notify({ type: 'warning', message: '发送太频繁, 请稍后再试' })
                return
            }
            this.sending = true
            let then = null
            if (this.scene === 'login') {
                then = sendSms
            } else {
                then = sendBindPhoneSms
            }
            then({ phone: this.phone }).then(res => {
                this.$notify({ type: 'success', message: res.message })
                this.$ls.set('sms_lock', 1, 60 * 1000)
                this.send = true
                this.waitingTimer = setInterval(() => {
                    if (this.waitingTime > 0) {
                        this.waitingTime--
                    }
                }, 1000)
            }).finally(() => {
                this.sending = false
            })
        }
    }
};
</script>
