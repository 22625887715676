import { render, staticRenderFns } from "./HandBox.vue?vue&type=template&id=26056805&scoped=true&"
import script from "./HandBox.vue?vue&type=script&lang=js&"
export * from "./HandBox.vue?vue&type=script&lang=js&"
import style0 from "./HandBox.vue?vue&type=style&index=0&id=26056805&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26056805",
  null
  
)

export default component.exports