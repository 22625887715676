<template>
    <van-popup v-model="visible" round position="bottom" closeable>
        <div class="bind-phone-header">绑定手机号</div>
        <van-form validate-first @submit="handleSubmit">
            <van-field
                v-model="form.phone"
                name="手机号"
                label="手机号"
                placeholder="请输入绑定手机号"
                :rules="[{ required: true, message: '请输入绑定手机号' }]"
            />
            <van-field
                v-model="form.code"
                center
                label="短信验证码"
                placeholder="请输入短信验证码"
                :rules="[{ required: true, message: '请输入绑定手机号' }]"
                >
                <template #button>
                    <bind-phone-button :phone=form.phone />
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" :loading="submitting">提交</van-button>
            </div>
        </van-form>
    </van-popup>
</template>

<script>
import BindPhoneButton from '@/components/BindPhoneButton'
import { bindPhone } from '@/api/user'
import { LOGIN_USER } from '@/utils/constants'

export default {
    name: 'BindPhone',
    components: { BindPhoneButton },
    data () {
        return {
            visible: false,
            submitting: false,
            form: {
                phone: '',
                code: ''
            }
        }
    },
    methods: {
        handleSubmit () {
            this.submitting = true
            bindPhone(this.form).then(res => {
                this.$ls.set(LOGIN_USER, res.data);
                this.$notify({ type: 'success', message: '绑定成功' })
                this.$emit('ok', res.data)
                this.visible = false
            }).finally(() => {
                this.submitting = false
            })
        },
        show () {
            this.visible = true
        }
    }
}
</script>

<style lang="less">
    .bind-phone-header {
        flex-shrink: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 48px;
        text-align: center;
    }
</style>
